<script>
import SwapVue from "../components/Swap.vue";
export default {
	name: 'Swap',
	components: {
		SwapVue,
	},
};
</script>

<template>
	<div class="container mx-auto mt-8">
		<SwapVue/>
	</div>
</template>

<style scoped></style>
